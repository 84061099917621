<template>
  <div class="clear">

    <div v-if="!getCustomWidgetAdd.preview"
         class=" mt-3 mb-3  input_icon_text d-flex align-items-center profile__box">
      <div class="content">
        <div class="url">Name</div>
        <div class="name">
          <div class="validation_input">
            <input :class="{'input-field-error' : validations.name}" type="text"
                   placeholder="Enter name of code block"
                   v-model="getCustomWidgetAdd.name" class="">
            <span class="input-error" v-if="validations.name">
                                {{ messages.name }}
                            </span>
          </div>
        </div>
      </div>
    </div>
    <div class=" mt-3 mb-3  input_icon_text d-flex align-items-center profile__box">
      <div class="content">
        <div class="url">Custom Javascript</div>
        <div class="name">
          <div class="validation_input">
            <div class="code_block">
              <code-editor v-model="getCustomWidgetAdd.js" class="custom-code-input" mode="javascript" theme="chrome"/>
            </div>

            <span class="input-error" v-if="validations.javascript">
                                {{ messages.javascript }}
                            </span>
          </div>
        </div>
      </div>
    </div>
    <div class=" mt-3 mb-3  input_icon_text d-flex align-items-center profile__box">
      <div class="content">
        <div class="url">Custom HTML</div>
        <div class="name">
          <div class="validation_input">

            <div class="code_block input_field  ">
              <div class="code_wrapper">
                <code-editor v-model="getCustomWidgetAdd.html" class="custom-code-input" mode="html" theme="chrome"/>

              </div>
              <span class="input-error" v-if="validations.html">
                                        {{ messages.html }}
                                    </span>
            </div>
            <template v-if="getCustomWidgetAdd.html">
              <template v-if="!getCustomWidgetAdd.preview">

                <template v-if="location==='campaign-steps'">
                  <div class="mt-3 mb-3   ">
                    <label class="d-block font-weight-bold " for="">When should it appear ?</label>

                    <div class="input_field ml-auto">
                      <div class=" mb-2 d-flex align-items-center">
                        <div class="radio_input_image mr-3 ">
                          <input value="time" v-model="getCustomWidgetAdd.trigger_type"
                                 type="radio" name="rules" id="Xsecond">
                          <label for="Xsecond" class="radio_left">After 'X' second.</label>
                        </div>

                        <input style="width: 100px;"
                               v-model="getCustomWidgetAdd.trigger_seconds"
                               class="inline_input with-border with-background  "
                               type="number">
                      </div>
                      <div class="radio_input_image ">
                        <input value="exit_intent" type="radio"
                               v-model="getCustomWidgetAdd.trigger_type" name="rules"
                               id="Xsecond1">
                        <label for="Xsecond1" class="radio_left">On Exit-intent</label>
                      </div>
                    </div>
                  </div>
                  <div class="input_field d-flex align-items-center mb-3">
                    <label class="font-weight-bold mr-3 mb-0">Where should it appear?</label>
                    <div class="">
                      <select class="inline_input with-border with-background  "
                              v-model="getCustomWidgetAdd.position">
                        <option value="bottom_left">Bottom Left</option>
                        <option value="bottom_right">Bottom Right</option>
                        <option value="middle">Middle</option>
                        <option value="top_left">Top Left</option>
                        <option value="top_right">Top Right</option>

                      </select>
                    </div>

                  </div>
                </template>

                <template v-else>
                  <div class="d-flex align-items-start">
                    <label class="d-block font-weight-bold " for="">When should it appear ?</label>

                    <div class="input_field ml-auto" style="min-width: 365px;">
                      <div class=" mb-2 d-flex align-items-center">
                        <div class="radio_input_image " style="min-width: 180px;">
                          <input value="time" v-model="getCustomWidgetAdd.trigger_type"
                                 type="radio" name="rules" id="Xsecond">
                          <label for="Xsecond" class="radio_left">After 'X' second.</label>
                        </div>

                        <input v-model="getCustomWidgetAdd.trigger_seconds"
                               class="inline_input with-border with-background w-auto"
                               type="number" min="0">
                      </div>
                      <div class="radio_input_image ">
                        <input value="exit_intent" type="radio"
                               v-model="getCustomWidgetAdd.trigger_type" name="rules"
                               id="Xsecond1">
                        <label for="Xsecond1" class="radio_left">On Exit-intent</label>
                      </div>
                    </div>
                  </div>
                  <div class="input_field d-flex align-items-center">
                    <label class="font-weight-bold" style="min-width: 180px;">Where should it
                      appear?</label>
                    <div style="min-width: 365px;" class="ml-auto">
                      <select class="inline_input with-border with-background  "
                              v-model="getCustomWidgetAdd.position">
                        <option value="bottom_left">Bottom Left</option>
                        <option value="bottom_right">Bottom Right</option>
                        <option value="middle">Middle</option>
                        <option value="top_left">Top Left</option>
                        <option value="top_right">Top Right</option>

                      </select>
                    </div>

                  </div>
                </template>

              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!--</template>-->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default ({
  props: {
    validations: {},
    messages: {},
    location: {
      default: ''
    }
  },
  computed: {
    ...mapGetters(['getCustomWidgetAdd'])
  },
  mounted () {
    // timer added because it takes some time for the code mirror to load up.
    // we can reduce the timer, but depending on the service call time, i have added 1 second delay
    setTimeout(() => {
      this.$refs.cssCode.refresh()
      this.$refs.scriptCode.refresh()
    }, 1000)
  },
  watch: {
    'getCustomWidgetAdd.trigger_seconds' (value) {
      if (value < 0) this.$set(this.getCustomWidgetAdd, 'trigger_seconds', 0)
    }
  }
})
</script>
