<template>
  <div class="cta_main_view w-100">
    <apply-utm-modal></apply-utm-modal>
    <div class="view_inner d-flex flex-column">

      <div class="view_center">
        <div class="steps_dot mb-3">
          <span class="dotted_circle active"></span>
          <span class="dotted_circle active"></span>
          <span class="dotted_circle active"></span>
          <span class="dotted_circle active"></span>
          <span class="dotted_circle"></span>
          <span class="dotted_circle"></span>
          <span class="dotted_circle" v-if="getCampaignAdd.campaign_type.retargeting"></span>
        </div>

        <div :class="{'input-field-error': getCampaignValidations.customMessageValidation.message_headline || getCampaignValidations.customMessageValidation.messageHeadlineLength}"
             class="input_icon_text d-flex align-items-center ">
          <div class="content">
            <div class="url">Message Headline</div>
            <div class="name">
              <div class="validation_input">
                <input
                  v-model="getCampaignAdd.message_headline" type="text" name=""
                  id=""
                  class="form-control txt-left-left" data-cy="headline">
              </div>
            </div>
          </div>
        </div>
        <span class="input-error" v-if="getCampaignValidations.customMessageValidation.message_headline">{{ getCampaignValidations.customErrorMessage.message_headline }}</span>
        <span class="input-error" v-else-if="getCampaignValidations.customMessageValidation.messageHeadlineLength">{{
            getCampaignValidations.customErrorMessage.messageHeadlineLength
          }}</span>

        <div :class="{'input-field-error': getCampaignValidations.customMessageValidation.message || getCampaignValidations.customMessageValidation.messageLength}"
             class="mt-3 input_icon_text d-flex align-items-center "
             v-if="getCampaignAdd.cta_type !== 'banner'">
          <div class="content">
            <div class="url">Message</div>
            <div class="name">
              <div class="validation_input">
                                  <textarea style="resize: none;"
                                            name=""
                                            id="" cols="30" rows="6"
                                            v-model="getCampaignAdd.message" data-cy="message"></textarea>
              </div>
            </div>
          </div>
        </div>
        <span class="input-error" v-if="getCampaignValidations.customMessageValidation.message">{{ getCampaignValidations.customErrorMessage.message }}</span>
        <span class="input-error" v-else-if="getCampaignValidations.customMessageValidation.messageLength">{{ getCampaignValidations.customErrorMessage.messageLength }}</span>

        <div class="mt-2 text-right mb-3" v-if="getCampaignAdd.cta_type === 'social_modern'">
          <p class="sub_text add_utm_link mt-0" v-if="getCampaigns.social_cta_stats">
            <span @click.prevent="toggleOpenBox">Show truncated version</span>
          </p>
          <p class="sub_text add_utm_link mt-0" v-else>
            <span @click.prevent="toggleOpenBox">Show full version</span>
          </p>
        </div>

        <div :class="{'input-field-error': getCampaignValidations.customMessageValidation.button_text || getCampaignValidations.customMessageValidation.buttonTextLength}"
             class="mt-3 input_icon_text d-flex align-items-center ">
          <div class="content">
            <div class="url">
              <template v-if="getCampaignAdd.type !== 'link'">
                Button Text
              </template>
              <template v-else>
                Link Text
              </template>
            </div>
            <div class="name">
              <div class="validation_input">
                <input
                  v-model="getCampaignAdd.button_text" type="text" name=""
                  id=""
                  class="form-control txt-left-left" data-cy="cta-text">
              </div>
            </div>
          </div>
        </div>
        <span class="input-error" v-if="getCampaignValidations.customMessageValidation.button_text">{{ getCampaignValidations.customErrorMessage.button_text }}</span>
        <span class="input-error" v-else-if="getCampaignValidations.customMessageValidation.buttonTextLength">{{ getCampaignValidations.customErrorMessage.buttonTextLength }}</span>

        <div :class="{'input-field-error': getCampaignValidations.customMessageValidation.url || getCampaignValidations.customMessageValidation.urlValid || getCampaignValidations.customMessageValidation.urlLength}"
             class="mt-3 input_icon_text d-flex align-items-center " v-if="getCampaignAdd.type !== 'form'">
          <div class="content">
            <div class="url">
              <template v-if="getCampaignAdd.type !== 'link'">
                Button URL (or phone number for click-to-call)
              </template>
              <template v-else>
                Link URL (or phone number for click-to-call)
              </template>
            </div>
            <div class="name">
              <!--            removed https prefix           @blur="getCampaignAdd.button_url = focusoutConcatenation(getCampaignAdd.button_url)"-->
              <div class="validation_input">
                <url
                  v-model="getCampaignAdd.button_url"
                  @onEnter="handleCTAMessageAction($event)"
                  :validations="getCampaignValidations.customMessageValidation"
                  v-bind:blur="() => getCampaignAdd.button_url = focusoutConcatenation(getCampaignAdd.button_url)"
                  ref="urlRef"
                  :checkTelephone="true"
                  :checkMail="true"
                  data-cy="cta-url"
                  placeholder="e.g. ‘https://mywebsite.com’ or ‘tel:+01273123456’"></url>
              </div>
            </div>
          </div>
        </div>
        <span class="input-error" v-if="getCampaignValidations.customMessageValidation.url">{{ getCampaignValidations.customErrorMessage.url }}</span>
        <span class="input-error" v-else-if="getCampaignValidations.customMessageValidation.urlLength">{{ getCampaignValidations.customErrorMessage.urlLength }}</span>
        <span class="input-error" v-else-if="getCampaignValidations.customMessageValidation.urlValid">{{ getCampaignValidations.customErrorMessage.urlValid }}</span>
        <div class="  text-right">
          <p class="add_utm_link mt-2" @click.prevent="$bvModal.show('applyUTMModal')">+ <span>Add UTM</span></p>
          <!--                    <apply-utm-link></apply-utm-link>-->
        </div>
        <!-- <div class=" col-md-12 " v-if="getCampaignAdd.type == 'retargeting'">

                <p class="checkbox">
                    <input type="checkbox" name="" id="redirectOrignal"
                           v-model="getCampaignAdd.redirect_original_website">
                    <label for="redirectOrignal">Redirect to original website</label>
                </p>

            </div>-->
      </div>

      <div class="view_bottom">
        <div class="view_bottom_inner text-center">

          <!-- <template v-if="getCallToActionsLoader.saveCallToAction">
               <a href="javascript:;" disabled="true"
                  class="boarding_btn txt-center btn btn&#45;&#45;disabled  btn&#45;&#45;loader">
                   <clip-loader :size="size" :color="color"></clip-loader>
                   Saving Campaign</a>
           </template>-->
          <template>
            <template>
              <button @click="$router.push({ 'name': 'call_to_action_type' })"
                      class="btn---cta  light-blue btn-round">
                <span>Go Back</span>
              </button>

              <button @click.prevent="handleCTAMessageAction()"
                      class="btn---cta btn-blue btn-round">
                <span>Next</span>
              </button>
            </template>

          </template>

        </div>
      </div>
    </div>
  </div>

</template>

<script>


import { mapActions, mapGetters } from 'vuex'
import Dropdown from '@/ui/Dropdown.vue'
import ApplyUTMLink from '@/views/pages/setting/utm/ApplyUTMLink'
import CustomWidgetAddForm from '@/views/pages/setting/custom-widget/forms/CustomWidgetAddForm'
import { campaignTypes } from '@/state/modules/mutation-types'
import ApplyUTMModal from '@/views/pages/setting/utm/ApplyUTMModal'
import URL from '@/ui/URL'

export default ({

  components: {
    Dropdown,
    'apply-utm-link': ApplyUTMLink,
    CustomWidgetAddForm,
    'apply-utm-modal': ApplyUTMModal,
    'url': URL
  },

  data () {
    return {
      size: '14px',
      color: '#fff',
      integrationLink: 'https://docs.replug.io/article/147-how-to-find-facebook-pixel-id',
      integrationName: 'Poptin'
    }
  },

  created () {
    if (!this.getCampaignAdd.brand_id) {
      this.$router.push({ name: 'create' })
    }
  },
  computed: {
    ...mapGetters([
      'getCampaignAdd',
      'getCampaigns',
      'getCustomWidgetList',
      'getCustomWidgetAdd',
      'getProfile',
      'getDomains',
      'getCampaignValidations'
    ]),
    widgetCode: {
      get () {
        return this.getCampaignAdd.script_ids
      },
      set (value) {
        this.getCampaignAdd.script_ids = value
      }
    }
  },
  methods: {
    ...mapActions([]),
    toggleOpenBox () {
      this.$store.commit(campaignTypes.SET_CAMPAIGN_SOCIAL_CTA_STATS, !this.getCampaigns.social_cta_stats)
    },
    handleCTAMessageAction () {
      if (!this.campaignMessageFormValidation()) this.$router.push({ name: 'customize' })
    }

  },

  watch: {
    'getCallToActionSelection.savingDetails.integration': function (name) {
      if (name == 'poptin') {
        this.integrationName = 'Poptin'
        this.integrationLink = 'https://docs.replug.io/article/147-how-to-find-facebook-pixel-id'
      } else if (name == 'wishloop') {
        this.integrationName = 'Wishloop'
        this.integrationLink = 'https://docs.replug.io/article/147-how-to-find-facebook-pixel-id'
      } else if (name == 'convertful') {
        this.integrationName = 'Convertful'
        this.integrationLink = 'https://docs.replug.io/article/147-how-to-find-facebook-pixel-id'
      } else if (name == 'optinmonster') {
        this.integrationName = 'OptinMonster'
        this.integrationLink = 'https://docs.replug.io/article/147-how-to-find-facebook-pixel-id'
      } else if (name == 'sumo') {
        this.integrationName = 'Sumo'
        this.integrationLink = 'https://docs.replug.io/article/147-how-to-find-facebook-pixel-id'
      }
    },
    'getCampaignAdd.message_headline' (value) {
      this.getCampaignValidations.customMessageValidation.message_headline = value && this.requiredCheck(value)
      this.getCampaignValidations.customMessageValidation.messageHeadlineLength = value && !this.maxLength(value, 100)
    },
    'getCampaignAdd.message' (value) {
      this.getCampaignValidations.customMessageValidation.message = value && this.requiredCheck(value)
      this.getCampaignValidations.customMessageValidation.messageLength = value && !this.maxLength(value, 500)
    },
    'getCampaignAdd.button_text' (value) {
      this.getCampaignValidations.customMessageValidation.button_text = value && this.requiredCheck(value)
      this.getCampaignValidations.customMessageValidation.buttonTextLength = value && !this.maxLength(value, 100)
    }
  }

})
</script>

<style>

</style>
